import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <div className="space-y-5">
                <p>
                    <strong>30 hores de temari i psicotècnics a classe</strong>
                </p>

                <p>
                    <strong>Dissabtes de 9.00 a 14.00 h</strong>
                </p>

                <p>
                    Matricula’t ara al curs i <strong>accedeix al campus online</strong> per avançar
                    el teu estudi amb les
                    <strong>diapositives actualitzades del temari</strong>. A més, hi trobaràs{" "}
                    <strong>actualitat, notícies i tests</strong>.
                </p>

                <p>
                    El primer dia de classe us donarem la <strong>nova guia Mossos de iOpos</strong>{" "}
                    en format llibre.
                </p>

                <p>
                    Al curs presencial ens centrarem en els{" "}
                    <strong>conceptes clau de cara a l’examen de 2025</strong>.
                </p>

                <p>
                    <strong>Calendari de classes (Dissabtes):</strong>
                </p>
                <ul>
                    <li>Inici del curs: 10 de maig</li>
                    <li>Classe 2: 17 de maig</li>
                    <li>Classe 3: 24 de maig</li>
                    <li>Classe 4: 31 de maig</li>
                    <li>Classe 5: 7 de juny</li>
                    <li>Fi de curs: 14 de juny</li>
                </ul>

                <p>
                    <strong>Calendari de classes (Diumenges):</strong>
                </p>

                <ul>
                    <li>Inici del curs: 25 de maig</li>
                    <li>Classe 2: 1 de juny</li>
                    <li>Classe 3: 8 de juny</li>
                    <li>Classe 4: 15 de juny</li>
                    <li>Classe 5: 22 de juny</li>
                    <li>Fi de curs: 29 de juny</li>
                </ul>

                <p>
                    El curs està impartit per <strong>una de les millors docents, la Laia</strong>.
                </p>

                <p>
                    L’accés al campus es tancarà el <strong>30 de juny de 2025</strong>.
                </p>

                <p>
                    El curs s’imparteix a{" "}
                    <strong>c/ Martí Codolar núm. 18, Hospitalet de Llobregat</strong>.
                </p>
            </div>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="El curs més intensiu, pràctic i complet per superar amb èxit les proves de Mossos d'Esquadra."
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};

export default Product;
